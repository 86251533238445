<template>
  <!--translate-->
  <div>
    <v-card class="mx-auto my-4 mb-8" max-width="800" flat outlined>
      <v-container class="mx-4 my-4">
        <v-row class="mb-3">
          <h3>{{ $t("settings.emailSettings") }}</h3>
          <div class="flex-grow-1"></div>
        </v-row>
      </v-container>
      <v-form class="pa-4 pt-0" ref="form" lazy-validation>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(text, i) in texts"
            v-bind:key="i"
            cols="12"
            sm="12"
            md="12"
          >
            <v-expansion-panel-header>
              <h4>{{ text.description }}</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <quill-editor
                class="largeTextField mb-6 ma-2"
                data-cy="action"
                v-model="text.value"
                :options="editorOptionAvail"
              ></quill-editor>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary" outlined @click="saveEmailSettings">{{
          $t("general.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    texts: [],
    editorOptionAvail: {
      placeholder: "Individuellen Email-Text hier einfügen...",
      modules: {
        toolbar: true,
      },
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("getSettingData", "emailTexts").then((item) => {
        this.texts.push(
          {
            key: "new_instruction_assignment",
            value: item.value.new_instruction_assignment,
            description:
              "Unterweisungsbenachrichtigungen an die zugewiesenen Mitarbeiter.",
          },
          {
            key: "new_examination_obligatory",
            value: item.value.new_examination_obligatory,
            description:
              "Untersuchungsbenachrichtigungen von verpflichtenden Untersuchungen an die zugewiesenen Mitarbeiter.",
          },
          {
            key: "new_examination_optional",
            value: item.value.new_examination_optional,
            description:
              "Untersuchungsbenachrichtigungen von angebotenen Untersuchungen an die zugewiesenen Mitarbeiter.",
          }
        );
      });
    },
    saveEmailSettings() {
      //set edited data
      //new_instruction_assignment
      const value = {
        new_instruction_assignment: this.texts[0].value,
        new_examination_obligatory: this.texts[1].value,
        new_examination_optional: this.texts[2].value,
      };
      this.$store.dispatch("setSettingData", {
        id: "emailTexts",
        value: value,
      });
    },
  },
};
</script>
<style scoped>
.largeTextField > .ql-container {
  min-height: 120px !important;
  font-size: 16px !important;
}

.largeTextField > .ql-toolbar {
  font-size: 16px !important;
}
.required-error {
  font-size: 12px;
  line-height: 16px;
}
</style>
